import Box from "@mui/material/Box";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ResponsiveGrid } from "./ResponsiveGrid";
import { useDBImages } from "./IndexedDB/useDBImages";
import { usePhotos } from "./photos";
// import { Button, ButtonGroup } from "@mui/material";
import ResponsiveAppBar from "./ResponsiveAppBar";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function App() {
  const photosAll = usePhotos()
  const { log, images } = useDBImages();

  return (

    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <ResponsiveAppBar />
      <div
        style={{
          textAlign: "center",
          margin: "auto",
          width: "100%",
          padding: "70px 10px 10px 10px",
        }}
      >
        {/* This domain is for sale, contact oleg<sub>.</sub>kyselov3<sub>@</sub>gmail<sub>.</sub>com for more detail. */}
        {/* <ButtonGroup color="secondary" aria-label="Medium-sized button group" sx={{ justifyContent: "space-between" }}>
          {buttons}
        </ButtonGroup> */}

        <Box sx={{ boxShadow: 4 }}>


          {photosAll.length !== images.length && <div>
            <div>
              <img src="/Loading.svg" alt="logo" height="5%" width="5%"></img></div><p style={{ color: "red" }} >Images are loading...{log}</p></div>}
          {/* <img src="/Logo.svg" alt="logo" height="33%" width="33%" style={{margin:'50px'}}></img> */}

          {photosAll.length !== images.length &&
            <div><img src="/Error.svg" alt="logo" height="5%" width="5%"></img></div>}
        </Box>
      </div>
      {/* <Settings>
        <Playground />
      </Settings> */}
      <ResponsiveGrid images={images} photos={photosAll} />
      {/* {isImageReady === true &&
        <ResponsiveGrid images={images} photos={[]} />
      } */}
      {/* {isImageReady === false &&
        <ResponsiveGrid images={[]} photos={photosAll} />
      } */}


    </ThemeProvider >

  );
}
