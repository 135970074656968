const randomIntFromInterval = (min: number, max: number): number => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getPhotoTitle = (index: number): string => {
  let min = 0;
  let max = photoTitles.length - 1;
  if (index <= 10) {
    min = randomIntFromInterval(10, max);
  }

  return photoTitles[Math.floor(Math.random() * (max - min + 1) + min)];
};
// const photoTitlesInBangla: string[] = [
//   "একটা মানুষ আমাকে এত ভালোবাসে,আমাকে নিয়ে ভাবে, ভাবতেই ভালো লাগে।🥰",
//   "আমি একদম ফ্রী ছিলাম, ওকে সময় হলে জানাবেন প্রিয়।😍",
// ];
const photoTitles: string[] = [
  "A Love to Remember",
  "The Sweetest Thing",
  "All of Me",
  "Just One Kiss",
  "The One That Got Away",
  "Love on the Line",
  "The Proposal",
  "Always and Forever",
  "The Notebook",
  "Can't Help Falling in Love",
  "The Perfect Match",
  "Crazy for You",
  "The Secret Crush",
  "Dreaming of You",
  "The Star-Crossed Lovers",
  "Everything You Are",
  "The Summer of Us",
  "Falling for You",
  "The Trouble with Love",
  "From This Moment On",
  "The Unexpected Bride",
  "Goodbye to Love",
  "The Valentine's Day Surprise",
  "Happily Ever After",
  "To All the Boys I've Loved Before",
  "I Do, I Do, I Do",
  "To Have and to Hold",
  "It Had to Be You",
  "True Love's Kiss",
  "Just the Way You Are",
  "Unforgettable You",
  "Kiss Me in the Moonlight",
  "Until You Came Along",
  "Let It Be Me",
  "What If It's Love?",
  "Love at First Sight",
  "When You Say Nothing at All",
  "Wherever You Are",

  // Added titles
  "Meant to Be",
  "Who Do You Love?",
  "Never Let Me Go",
  "Wish You Were Here",
  "Only You",
  "You're the One",
  "Say You'll Stay",
  "Come Away with Me",
  "Forever Yours",
  "You Had Me at Hello",
  "Something Borrowed, Something Blue",
  "You Make Me Feel",
  "Stay with Me",
  "Close to You",
  "Yours Truly",
  "You and Me Against the World",
  "Some Kind of Wonderful",
  "You're Still the One",
  "Take a Chance on Me",
  "Closer Than Ever",
  "My One and Only",
  "You're My Everything",
  "Save the Last Dance for Me",
  "Can't Take My Eyes Off of You",
  "My Best Friend's Wedding",
  "You're So Fine",
  "Take My Breath Away",
  "Catch Me If You Can",
  "My Favorite Mistake",
  "You're a Dream Come True",
  "No One Else But You",
  "You're the Reason I Smile",
  "Make Me Yours",
  "Call Me Maybe",
  "My Heart Belongs to You",
  "You're the Best Thing That Ever Happened to Me",
  "Love Me Like You Do",
  "Chasing You",
  "My Lucky Star",
  "You're Irresistible",

  // More added titles
  "How to Lose a Guy in Ten Days",
  "You're the Cream in My Coffee",
  "Loving You Is Easy",
  "Breathe Me In",
  "Hold Me Tight",
  "You're a Keeper",
  "Marry Me Today, Love Me Forever",
  "As Long as You Love Me",
  "I'm Yours, You're Mine",
  "Dance with Me Tonight",
  "You're My Sunshine",
  "I'll Be There for You",
  "Just Like Heaven",
  "You're a Piece of Cake",
  "Love You More Than Words Can Say",
  "I'm Crazy About You",
  "Be Mine Forevermore ",
  "I'll Never Stop Loving You ",
  "Just Can't Get Enough of You ",
  "You're a Work of Art ",
  "I Want to Grow Old with You ",
  "You're My Soul Mate ",
  "Kiss Me Again ",
  "I'm So Into You ",
  "You're a Breath of Fresh Air ",
  "I'm Head Over Heels for You ",
  "Melt My Heart ",
  "I'm Hooked on You ",
  "You're a Blast ",
  "You Rock My World ",
];
