import { useEffect, useState } from "react";
import { Images, Stores, addData, getStoreData, initDB } from "./db";
import { usePhotos } from "../photos";
import axios, { AxiosRequestConfig } from 'axios';
import { Buffer } from 'buffer';
import { getPhotoTitle } from "../PhotoRandomTitle";
const config = {
    responseType: 'arraybuffer',
} as AxiosRequestConfig


export const useDBImages = () => {
    const [images, setImages] = useState<Images[]>([]);
    const [loadingState, setLoadingState] = useState<'Loading' | 'Loaded' | 'Error'>('Loading');
    const [isDBReady, setIsDBReady] = useState<boolean>(false);
    const [isImageReady, setIsImageReady] = useState<boolean>(false);
    const [log, setLog] = useState<string>();
    const photosAll = usePhotos()

    useEffect(() => {
        setImages([]);
        setLoadingState("Loading");
        setIsDBReady(false);
        setIsImageReady(false);
        setLog('');
        // let dbImages: Images[] = [];
        async function fetchData() {
            await initDB();
            setIsDBReady(true)
            // do {
            const dbImages = await getStoreData<Images>(Stores.Images);
            if (photosAll.length > dbImages.length) {
                const output = photosAll.filter(p => !dbImages.find(i => i.fileId === p.fileName));
                // console.log(`${output.length} items will be added`);
                output.forEach(async (photo, index) => {
                    setLoadingState('Loading');
                    let isExists = dbImages.find(s => s.fileId === photo.fileName);
                    if (isExists === undefined) {
                        await axios.get(photo.src, config).then(async response => {
                            const df = 'data:image/png;base64,' + Buffer.from(response.data, 'binary').toString('base64')
                            const systemDocFile = { fileId: photo.fileName, height: photo.height, width: photo.width, blob: df, alt: getPhotoTitle(index) } as Images;
                            await addData(Stores.Images, systemDocFile);
                            isExists = dbImages.find(s => s.fileId === photo.fileName);
                            if (isExists === undefined) { //Double check for Async
                                dbImages.push(systemDocFile);
                            }
                        }).catch((e) => {
                            //setLoadingState('Error');
                            // console.clear();
                            // console.error(e);
                        }).finally(() => {
                            // console.log(`${dbImages.length}/${photosAll.length} item added`);
                            setLog(`${Math.round((dbImages.length / photosAll.length) * 100)}%`);
                            if (photosAll.length === dbImages.length) {
                                setImages(dbImages);
                                setLoadingState('Loaded');
                                setIsImageReady(true);
                            }
                        });
                    }
                })
            } else {
                setImages(dbImages);
                setLoadingState('Loaded');
                setIsImageReady(true);
            }
            //} while (photosAll.length > images.length)

        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     async function fetchData() {
    //         if (isDBReady === false) {
    //             setLoadingState('Loading');
    //             await initDB();
    //             setIsDBReady(true)
    //             setLoadingState('Loaded');
    //         }
    //     }
    //     fetchData();
    // }, [isDBReady])

    // useEffect(() => {
    //     async function insertImageData() {
    //         let dbImages = await getStoreData<Images>(Stores.Images);
    //         if (photosAll.length !== dbImages.length) {
    //             photosAll.forEach(async (photo, index) => {
    //                 setLoadingState('Loading');
    //                 const isExists = dbImages.find(s => s.fileId === photo.fileName);
    //                 if (isExists === undefined) {
    //                     await axios.get(photo.src, config).then(async response => {
    //                         const df = 'data:image/png;base64,' + Buffer.from(response.data, 'binary').toString('base64')
    //                         const systemDocFile = { fileId: photo.fileName, height: photo.height, width: photo.width, blob: df, alt: getPhotoTitle(index) } as Images;
    //                         await addData(Stores.Images, systemDocFile);
    //                     }).catch(() => {
    //                         setLoadingState('Error');
    //                     });
    //                 }
    //             })
    //             dbImages = await getStoreData<Images>(Stores.Images);
    //         }
    //         setIsImageInserted(photosAll.length === dbImages.length);
    //         setLoadingState('Loaded');
    //     }
    //     if (isDBReady === true && isImageInserted === false) {
    //         insertImageData();
    //     }

    // }, [isDBReady, isImageInserted, photosAll]);

    // useEffect(() => {
    //     async function fetchImageList() {
    //         try {
    //             setLoadingState('Loading');
    //             const dbImages = await getStoreData<Images>(Stores.Images);
    //             setImages(dbImages);
    //             setIsImageReady(true);
    //             setLoadingState('Loaded');
    //         } catch {
    //             setLoadingState('Error');
    //         }
    //     }
    //     if (isDBReady === true && isImageInserted === true && isImageReady === false) {
    //         fetchImageList();
    //     }
    // }, [isDBReady, isImageInserted, isImageReady]);

    return { log, loadingState, isDBReady, isImageReady, images };
}
