import { getPhotoTitle } from "./PhotoRandomTitle";
import images from "./images.json";
export interface PhotosModel {
  idx: number;
  id: string;
  width: number;
  height: number;
}

export interface Photo {
  fileName: string;
  src: string;
  thumbSrc: string;
  /** Image width in pixels. */
  width: number;
  /** Image height in pixels. */
  height: number;

  key?: string;
  /** Optional image `alt` attribute. */
  alt: string;
  /** Optional image `title` attribute. */
  title: string;
}

export function shuffleArray<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
export const usePhotos = () => {
  const unsplashLink = (name: string) => `/photography/original/${name}`;
  const thumbLink = (name: string) => `/photography/thumb/${name}`;
  let photos: Photo[] = [];

  const unsplashPhotosAll = shuffleArray(images.images);
  const unsplashPhotos = unsplashPhotosAll;//.slice(0, 1);

  photos = unsplashPhotos.map(
    (photo, idx) =>
    ({
      fileName: photo.id,
      src: unsplashLink(photo.id),
      width: photo.width,
      height: photo.height,
      title: getPhotoTitle(idx),
      thumbSrc: thumbLink(photo.id),
    } as Photo)
  );
  return photos;
};
