import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Masonry from "@mui/lab/Masonry";
import ScrollDialog from "./Dialog";
import { MyLazyLoadImage } from "./MyLazyLoadImage";
import { Images } from "./IndexedDB/db";
import { Photo, shuffleArray } from "./photos";
import { getPhotoTitle } from "./PhotoRandomTitle";
interface Props {
  images: Images[];
  photos: Photo[];
}

export const ResponsiveGrid = ({ images, photos }: Props) => {

  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [mergedImages, setMergedImages] = useState<{
    src: string;
    alt: string;
  }[]>([]);

  useEffect(() => {
    const output = photos.filter(p => !images.find(i => i.fileId === p.fileName));

    const imageList = images.map(
      (photo, idx) =>
      ({
        src: photo.blob,
        alt: getPhotoTitle(idx)
      })
    );
    const photoList = output.map(
      (photo, idx) =>
      ({
        src: photo.thumbSrc,
        alt: getPhotoTitle(idx)
      })
    );

    // console.log(`added : ${imageList.length}`);
    // console.log(`yet to added : ${photoList.length}`);
    setMergedImages(shuffleArray([...imageList, ...photoList]));
  }, [images, photos]);

  const onPhotoClick = (index: number) => {
    setCurrentIndex(index);
    setOpen(true);
  };
  return (
    <Box>
      {open && (
        <ScrollDialog
          images={mergedImages}
          currentIndex={currentIndex}
          open={open}
          setOpen={setOpen}
        />
      )}
      <Masonry columns={3} spacing={2}>
        {mergedImages.length > 0 && mergedImages.map((item, index) => (
          <div
            key={index}
            style={{ cursor: "pointer" }}
            onClick={() => onPhotoClick(index)}
          >
            <MyLazyLoadImage
              src={item.src} alt={item.alt} />
          </div>
        ))}
        {/* {photos.length > 0 && photos.map((item, index) => (
          <div
            key={index}
            style={{ cursor: "pointer" }}
          >
            <MyLazyLoadImage
              src={item.thumbSrc} alt={item.alt} />
          </div>
        ))} */}
      </Masonry>
    </Box>
  );
};
