
interface ImageProps {
  src: string;
  alt: string
}

// const errorImg = "./error.svg";
// const loadingImg = "./Loading.svg";
export const MyLazyLoadImage = ({ src, alt }: ImageProps) => {

  // const [imageSource] = useState<string>(image.blob);

  return (
    <img alt={alt}
      src={src}
      style={{
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        display: "block",
        width: "100%",
      }} />
  );
};
