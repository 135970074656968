import * as React from "react";
import Lightbox from "./CoolLightbox";
import { useEffect, useState } from "react";
interface ImagePopUpProps {
  images: {
    src: string;
    alt: string;
  }[];
  currentIndex: number;
  open: boolean;
  setOpen: (value: boolean) => void;
}
export default function ScrollDialog({
  images,
  currentIndex,
  open,
  setOpen,
}: ImagePopUpProps) {
  // const [dbImages, setDbImages] = useState<{
  //   src: string;
  //   alt: string;
  // }[]>([]);
  // useEffect(() => {
  //   const imageList = images.map(
  //     (photo) =>
  //     ({
  //       src: photo.blob,
  //       alt: photo.alt
  //     })
  //   );
  //   setDbImages(imageList);
  // }, [images]);

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [currentImageIndex, setCurrentIndex] = useState(currentIndex);

  return (
    <Lightbox
      isOpen={open}
      onClose={handleClose}
      images={images}
      currentImageIndex={currentImageIndex}
      setCurrentIndex={setCurrentIndex} />
  );
}
